.loading-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
  
    .spinner {
      border: 3px solid #f0b90b;
      border-radius: 50%;
      border-top: 3px solid #50462fc1;
      width: 250px;
      height: 250px;
      animation: spin 2s linear infinite;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.3);
      padding: 20px;
    }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  