/* Instructions.scss */
.instructions {
    margin-top: 100px !important;
    padding: 20px 30px;
    background-color: #1f1f1f;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3), 0 0 15px rgba(240, 185, 11, 0.9);
    margin: 20px auto;
    max-width: 800px;

    @media (max-width: 768px) {
        padding: 15px 20px;
    }

    @media (max-width: 480px) {
        padding: 10px 15px;
        margin-top: 80px !important;
    }
}

.instructions h1 {
    text-align: center;
    color: gold;
    margin-bottom: 30px;
    font-size: 34px;
    font-weight: bold;

    @media (max-width: 480px) {
        font-size: 28px;
        margin-bottom: 20px;
    }
}

.instructions ol {
    line-height: 1.6;
    padding-left: 20px;

    @media (max-width: 480px) {
        padding-left: 15px;
    }
}

.instructions ol > li {
    margin-bottom: 15px;

    @media (max-width: 480px) {
        margin-bottom: 10px;
    }
}

.instructions ul {
    margin-top: 10px;
    margin-bottom: 20px;
}

.instructions ul > li {
    margin-bottom: 10px;
}

.youtube-video {
    iframe {
        width: 100%;
        height: auto;
        max-width: 100%;
        aspect-ratio: 16/9;
    }
}
