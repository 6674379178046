.notifications-container {
    max-width: 1200px;
    margin: 90px auto 0;
    padding: 1rem;
    background-color: rgba(18, 18, 18, 0.9);
    border-radius: 5px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  
   
  
    .notifications-content {
      .notifications-title {
        color: #F0B90B;
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }
  
   
  
      .notifications-list {
        .notification-item {
          background-color: rgba(255, 255, 255, 0.05);
          border-radius: 5px;
          padding: 1rem;
          margin-bottom: 1rem;
          cursor: pointer;
          transition: background-color 0.3s;
  
          &:hover {
            background-color: rgba(255, 255, 255, 0.1);
          }
  
          .notification-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0.5rem;
  
            strong {
              color: #F0B90B;
            }
  
            small {
              color: rgba(255, 255, 255, 0.6);
            }
          }
  
          .notification-body {
            color: white;
  
            p {
              margin-top: 0.5rem;
              font-style: italic;
            }
          }
        }
      }
    }
  }