.inbox-container {
    max-width: 90%;
    margin: 0 auto;
    padding: 20px;
    background-color: #1b1b1b;
    border-radius: 8px;
    color: #ffffff;
}

.inbox-content {
    display: flex;
    flex-direction: column;
}

.inbox-title {
    font-size: 30px;
    margin-bottom: 20px;
    text-align: center;
    color: #F0B90B;
}

.view-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
    height: 60px;

    button {
        background-color: #333333;
        color: #F0B90B;
        border: none;
        padding: 10px 20px;
        margin: 0;
        cursor: pointer;
        transition: background-color 0.3s;
        width: 50%;
        box-sizing: border-box;
        font-size: 25px;

        &.active,
        &:hover {
            background-color: #F0B90B;
            color: #333333;
        }
    }
}

.message-list {
    background-color: #262626;
    padding: 20px;
}

.message-item {
    border-bottom: 1px solid #444444;
    padding: 10px 0;
    cursor: pointer;
    transition: background-color 0.3s;

    &.selected {
        background-color: #333333;
    }

    .message-header,
    .message-body,
    .message-footer {
        margin-bottom: 10px;
        padding: 8px;
    }

    .message-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: aqua;
    }

    .message-header {
        color: #ffcc00;
    }
}

.delete-button2 {
    background-color: #ff4d4d;
    color: #ffffff;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 20%;
    height: 50px;

    &:hover {
        background-color: #cc0000;
    }
}

.message-details {
    margin-top: 20px;
    background-color: #333333;
    padding: 20px;
    border-radius: 8px;
}

.reply-date {
    color: rgba(0, 255, 255, 0.624);
    margin-top: 0.5em;
    display: block;
}

.reply-section {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    .reply-textarea {
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 4px;
        margin-bottom: 10px;
        resize: none;
        position: relative;
        z-index: 1;
    }

    .reply-button {
        background-color: #F0B90B;
        color: #333333;
        border: none;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;
        height: 60px;
        font-size: 25px;

        &:hover {
            background-color: #000000;
            color: #F0B90B;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.3), 0 0 15px rgba(240, 185, 11, 0.9);
        }
    }
}

.replies {
    margin-top: 20px;

    .reply-item {
        margin-bottom: 10px;
        max-width: 70%;
        padding: 10px;
        border-radius: 4px;
        word-wrap: break-word;
        word-break: break-all;
        overflow: hidden;
        font-size: 0.9rem; // Ajustar el tamaño de la fuente

        .message-footer {
            font-size: 0.8rem; // Ajustar el tamaño de la fuente para las fechas
        }
    }

    .reply-contraparte {
        background-color: #262626;
        align-self: flex-start;
        color: #ffffff;
        padding: 10px;
        border-radius: 15px;
        overflow: hidden;
        word-wrap: break-word;
        max-width: 70%;
        margin-right: auto;
    }

    .reply-user {
        background-color: #444444;
        align-self: flex-end;
        color: #ffffff;
        padding: 10px;
        border-radius: 15px;
        overflow: hidden;
        word-wrap: break-word;
        max-width: 70%;
        margin-left: auto;
    }
}

@media (max-width: 600px) {
    .inbox-title {
        font-size: 24px;
    }

    .view-buttons {
        flex-direction: column;
        height: auto;

        button {
            padding: 8px 12px;
            margin-bottom: 5px;
            font-size: 18px;
            width: 100%;
        }
    }

    .message-item {
        padding: 8px 0;

        .message-header,
        .message-body,
        .message-footer {
            padding: 6px;
            font-size: 14px;
        }

        .message-footer {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .delete-button2 {
        padding: 3px 6px;
        width: auto;
        height: auto;
    }

    .reply-section {
        .reply-textarea {
            padding: 8px;
        }

        .reply-button {
            padding: 8px;
            font-size: 18px;
        }
    }

    .replies {
        .reply-item {
            max-width: 100%;
            font-size: 0.8rem; // Reducir tamaño de la fuente en móviles

            .message-footer {
                font-size: 0.7rem; // Reducir tamaño de la fuente para las fechas en móviles
            }
        }

        .reply-contraparte, .reply-user {
            max-width: 100%;
        }
    }
}
