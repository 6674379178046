.publication-container {
  background-color: #121314;
  padding: 20px;
}

.form-columns {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
  gap: 10px; // Espacio entre columnas

  // Cambiar el diseño a columna en pantallas pequeñas
  @media (max-width: 1200px) {
    flex-direction: column;
  }
}

.left-panel,
.right-panel {
  width: 48%;
  background-color: #333;
  padding: 10px; // Reducir padding
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(240, 185, 11, 0.9);
  background: #1d1d1f;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3), 0 0 15px rgba(240, 185, 11, 0.9);

  h2 {
    color: #f0b90b;
    margin-bottom: 10px; // Reducir margen inferior
    font-size: 1.5rem;
  }

  .input-label {
    display: block;
    margin-bottom: 5px;
    color: #f0b90b;
    font-size: 1rem;
  }

  .input-box {
    width: 100%;
    padding: 6px 8px; // Reducir padding
    border: 1px solid #333;
    border-radius: 4px;
    margin-bottom: 10px; // Reducir margen inferior
    background-color: #282828;
    color: #c9c9c9;
    font-size: 0.9rem;
  }
}

// Ajustar los paneles en pantallas pequeñas
@media (max-width: 1200px) {
  .form-columns {
    flex-direction: column; // Apilar los paneles verticalmente
  }

  .left-panel,
  .right-panel {
    width: 100%; // Ocupa el ancho completo en pantallas pequeñas
  }
}

.radio-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.radio-label {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 0.9rem;
}

.radio-label input[type="radio"] {
  margin-right: 5px;
}

.component-button2 {
  display: inline-block;
  padding: 10px 10px;
  width: 100%;
  height: 50px; // Reducir altura del botón
  background-color: #f0b90b;
  color: #121212;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1rem;

  &:hover {
    background-color: #0c9e21;
    box-shadow: 7px 1px 7px rgb(224, 188, 8);
  }

  &:disabled {
    background-color: #a2a7ae;
    cursor: not-allowed;
  }
}
