body {
  font-family: "Lato", sans-serif;
  background-color: #1D1D1F;
  color: #FFFFFF;
  font-size: 22px; /* Aumentado para móviles */
}

.mobile-homepage {
  display: flex;
  flex-direction: column; /* Para que los elementos estén uno debajo del otro en móviles */
  align-items: center;
  padding: 20px;
  background-color: #121212;
}

.mobile-left-panel {
  max-width: 95%; /* Uso porcentual para adaptarse a la anchura del dispositivo */
  width: 100%;
  background-color: #1f1f1f;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3), 0 0 15px rgba(240, 185, 11, 0.9);
  text-align: center;
  margin-top: 30px;
}

.mobile-left-panel h1,
.mobile-left-panel h3 {
  margin: 10px 0; /* Reducido para móviles */
  color: #F0B90B;
  font-size: 50px !important; /* Aumentado para móviles */
}

.mobile-left-panel .mobile-logo {
  width: 250px; /* Aumentado para móviles */
  animation: logoAnimation 1s forwards;

  &.spinning {
    animation: spin 3s linear infinite;
  }
}

.mobile-left-panel .mobile-logs {
  margin-top: 20px;
  font-size: 22px; /* Aumentado para móviles */
  color: #c9c9c9;
}

.mobile-left-panel .mobile-input-box {
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 6px;
  border: 1px solid #333;
  background-color: #282828;
  color: #c9c9c9;
  font-size: 40px !important;
}

.mobile-left-panel .mobile-input-label {
  display: block;
  margin-bottom: 8px;
  color: #F0B90B;
  font-size: 40px; /* Aumentado para móviles */
}

.mobile-left-panel .mobile-component-button {
  display: block;
  width: 100%;
  padding: 5px;
  margin-top: 10px;
  border: none;
  border-radius: 6px;
  background-color: #F0B90B;
  color: #1f1f1f;
  font-size: 50px;
  cursor: pointer;

  &:disabled {
    background-color: #777;
    cursor: not-allowed;
  }
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes logoAnimation {
  0% {
    width: 0px;
    transform: rotate(0deg);
  }
  100% {
    width: 250px; /* Ajustado para móviles */
    transform: rotate(-360deg);
  }
}

.mobile-checkbox-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Reducido para móviles */

  h3 {
    margin: 0;
    font-size: 22px; /* Aumentado para móviles */
  }

  input[type="checkbox"] {
    appearance: none;
    background-color: #282828;
    width: 100px;
    height: 100px;
    border: 8px solid #F0B90B;
    cursor: pointer;
    position: relative;

    &:checked::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 33px;
      height: 33px;
      background-color: #F0B90B;
      transform: translate(-50%, -50%);
      border-radius: 2px;
    }
  }
}

*,
*::before,
*::after {
    box-sizing: border-box;
}


// icono PC modo escritorio

.mobile-button {
  position: fixed;
  top: 23%;
  right: 45px;
  transform: translateY(-50%);
  z-index: 9999;
  padding: 20px;
 
}

.mobile-button a {
  display: block;
  text-align: center;
}

.mobile-button img {
  width: 33px; // Tamaño del icono del PC
  height: 33px;
}
