.card {
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  padding: 15px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1rem;
  background-color: #1212129d;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3), 0 0 7px rgba(240, 185, 11, 0.9);
  color: #c9c9c9;
  overflow: hidden;

  h1,
  h2,
  h3,
  h4 {
    color: #F0B90B;
  }

  @media (max-width: 600px) {
    padding: 10px;
    font-size: 0.9rem;
  }
}

.search-form {
  width: 90%;
  max-width: 600px;
  margin: 12px auto 20px;
  padding: 20px;
  border-radius: 12px;
  background-color: #1212129d;
  color: #F0B90B;
  border: 1px solid #F0B90B;
  z-index: 10;
  position: relative;
}

.input {
  width: 100%;
  height: 33px;
  background-color: #282828;
  border-color: #333;
  margin-bottom: 10px;
  border-radius: 7px;
  color: #c9c9c9;
  border: 1px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3), 0 0 4px rgba(240, 185, 11, 0.9);
  font-size: 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.button {
  width: 100%;
  color: #c9c9c9;
  background-color: #333;
  height: 50px;
  font-size: 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.button:hover {
  color: #F0B90B;
}

.search-form button {
  height: 50px;
  background-color: #F0B90B;
  color: #282828;
  border: none;
  padding: 1px 30px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  width: calc(50% - 5px);
  margin-bottom: 10px;
  margin-top: 20px;
  margin-right: 10px;
  font-size: 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.search-form button:last-child {
  margin-right: 0;
}

.search-form button:hover {
  color: #ffffff;
  background-color: #1d4bd7;
}

.search-form button:active {
  background-color: #8a6d2c;
}

.radio-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.radio-label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  input[type="radio"] {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  input[type="radio"]:checked:before {
    width: 12px;
    height: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
  }
}

@media (max-width: 600px) {
  .radio-group {
    flex-direction: column;
    align-items: flex-start;
  }

  .radio-label {
    width: 100%;
    justify-content: space-between;
  }
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.button2,
.delete-button {
  background-color: #1f1f1f;
  color: #F0B90B;
  border: 1px solid #F0B90B;
  border-radius: 15px;
  height: 40px;
  font-size: 1rem;
  flex: 1 1 calc(50% - 10px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
}

.delete-button:hover {
  color: #1f1f1f;
  background-color: #ff4d4d;
}

.button2:hover {
  color: #1f1f1f;
  background-color: #F0B90B;
}

.comment-input-container {
  margin-top: 10px;
  max-width: 100%;
  overflow: hidden;
}

.comment-section {
  margin-top: 10px;
  max-width: 100%;
  overflow: hidden;

  .comment {
    margin-bottom: 10px;
    padding: 15px;
    background-color: #282828;
    border-radius: 15px;
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
  }

  .comment-text {
    color: #ccc;
    font-size: 1rem;

    @media (max-width: 600px) {
      font-size: 0.9rem;
    }
  }

  .comment-details {
    color: rgb(175, 170, 170);
    font-size: 0.9rem;
    font-family: 'Courier New';

    @media (max-width: 600px) {
      font-size: 0.8rem;
    }
  }
}

.chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.input-comentarios {
  height: 100px;
  width: 100%;
  margin: 10px 0;
  padding: 10px 15px;
  border-radius: 15px;
  border: none;
  background-color: #333;
  color: #F0B90B;
  font-size: 1rem;
}

.Send {
  width: 100%;
  padding: 15px 20px;
  margin-top: 10px;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  background-color: #F0B90B;
  color: #1f1f1f;
  font-size: 1rem;
  text-align: center;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Send:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.interaction-buttons button {
  background: none;
  border: none;
  cursor: pointer;
  color: #F0B90B;
  margin-right: 10px;
  font-size: 1.2rem;
}

.Send:hover {
  color: #ffffff;
  background-color: #1d4bd7;
}

.comment-text {
  color: #ccc;
  font-size: 1rem;
}

.comment-details {
  color: rgb(175, 170, 170);
  font-size: 0.9rem;
  font-family: 'Courier New';
}

.comment-text,
.comment-details {
  white-space: normal;
}

.interaction-buttons {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;

  @media (max-width: 600px) {
    padding: 5px;
    gap: 10px;
  }
}

.like-button,
.dislike-button {
  border: none;
  background: #1f1f1f;
  cursor: pointer;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 15px;

  &:hover {
    color: black;
  }

  @media (max-width: 600px) {
    width: 35px;
    height: 35px;
    font-size: 0.9em;
  }
}

.like-button {
  color: #2fc610;

  &:hover {
    background-color: #22be11;
  }

  &.liked {
    box-shadow: 0 4px 8px rgba(0, 255, 0, 0.5);
    background-color: #1f1f1f;
  }
}

.dislike-button {
  color: #eb1010;

  &:hover {
    background-color: #ea1010;
  }

  &.disliked {
    box-shadow: 0 4px 8px rgba(255, 0, 0, 0.5);
    background-color: #1f1f1f;
  }
}

.star-button {
  background-color: #1f1f1f;
  border: none;
  cursor: pointer;
  padding: 15px;
  margin: 5px;
  transition: background-color 0.3s ease;
  border-radius: 15px;
  height: 60px;
  align-items: center;
  width: calc(20% - 10px);
}

.star-button:hover {
  background-color: #555;
}

.star-red {
  color: red;
}

.star-red:hover {
  color: darkred;
}

.star-yellow {
  color: yellow;
}

.star-yellow:hover {
  color: goldenrod;
}

.star-orange {
  color: orange;
}

.star-orange:hover {
  color: darkorange;
}

.star-light-green {
  color: lightgreen;
}

.star-light-green:hover {
  color: limegreen;
}

.star-dark-green {
  color: darkgreen;
}

.star-dark-green:hover {
  color: forestgreen;
}

.user-date,
.user-travel {
  margin-top: 15px;
  border-radius: 15px;
  padding: 10px;
  background-color: #282828;
  color: #e9dcb2;

  span {
    display: block;
  }
}

.user-date {
  border: 1px solid #F0B90B;
  text-align: left;

  @media (max-width: 600px) {
    padding: 8px;
  }
}

.buton-telefono {
  background-color: #1f1f1f;
  color: #F0B90B;
  border: 1px solid #F0B90B;
  border-radius: 15px;
  height: 40px;
  width: auto;
  font-size: 0.9rem;
  margin-top: 10px;

  &:hover {
    background-color: #F0B90B;
    color: #1f1f1f;
  }

  @media (max-width: 600px) {
    height: 35px;
    font-size: 0.8rem;
  }
}

.copy-enlace {
  background-color: #F0B90B;
  border-radius: 15px;
  height: 40px;
  width: auto;
  cursor: pointer;
  transition: background-color 0.3s;
  flex: 1 1 calc(50% - 10px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
}

.copy-enlace:hover {
  background-color: #1d4bd7;
}

@media (max-width: 600px) {
  .button2,
  .delete-button,
  .copy-enlace {
    height: 35px;
    font-size: 0.9rem;
  }
}
