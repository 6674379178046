.component-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(18, 18, 18, 0.9);
  backdrop-filter: blur(5px);
  border-bottom: 2px solid rgb(46, 46, 46);
  z-index: 1000;
  height: 80px;

  .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.6%;
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }

  .logo-container {
    position: relative;
    display: flex;
    align-items: center;

    .nav-logo {
      display: flex;
      align-items: center;
      color: white;
      text-decoration: none;
      font-size: 1.3rem;
      font-weight: 600;

      img {
        max-width: 50px;
        margin-right: 0.5rem;
      }
    }

    .stat-card {
      position: absolute;
      top: 100%;
      left: 0;
      background-color: rgba(18, 18, 18, 0.9);
      border-radius: 5px;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
      padding: 1rem;
      width: 250px;
      z-index: 101;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s;

      h3 {
        color: #F0B90B;
        margin-bottom: 0.5rem;
      }

      .stat-item {
        margin-bottom: 0.5rem;

        .stat-title {
          color: #F0B90B;
          font-size: 0.9rem;
        }

        .stat-value, .stat-percentage {
          color: white;
          font-size: 0.8rem;
        }
      }
    }

    &:hover .stat-card {
      opacity: 1;
      visibility: visible;
      transform: translateY(10px);
    }
  }

  .menu-items {
    display: flex;
    align-items: center;

    .hamburger-button {
      display: block;
      background: none;
      border: none;
      color: #F0B90B;
      font-size: 1.5rem;
      cursor: pointer;
      margin-right: 5px; /* Ajuste de margen derecho */
      margin-left: 5px; /* Ajuste de margen izquierdo */

      @media (min-width: 781px) {
        display: none;
      }
    }

    .desktop-menu {
      display: none;

      @media (min-width: 781px) {
        display: flex;
      }

      .nav-links {
        color: white;
        text-decoration: none;
        padding: 0.5rem 1rem;
        font-size: 1.2rem;
        transition: color 0.3s;

        &:hover {
          color: #F0B90B;
        }
      }
    }

    .component-buttonM {
      background: linear-gradient(252.53deg, #271f38 -21.28%, rgba(127, 67, 255, 0) 61.97%);
      border: 1px solid #F0B90B;
      color: white;
      padding: 0.16rem 1rem;
      border-radius: 25px;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 0.7rem;
     
      

      img {
        max-width: 40%;
        margin-left: 0.5rem;
        height: 35px;
      }

      &:hover {
        background: linear-gradient(0deg, hsla(0, 0%, 100%, 0.2), hsla(0, 0%, 100%, 0.2)), linear-gradient(252.53deg, #271f38 -21.28%, rgba(127, 67, 255, 0) 61.97%);
      }
    }
  }

  .sidebar {
    position: fixed;
    top: 80px;
    left: -270px;
    width: 195px;
    height: calc(100vh - 80px);
    background-color: rgba(18, 18, 18, 0.9);
    transition: left 0.3s ease-in-out;
    z-index: 1000;
    padding: 1rem;

    &.show {
      left: 0;
    }

    .nav-links {
      display: block;
      color: white;
      text-decoration: none;
      padding: 0.8rem 0;
      font-size: 1rem;
      transition: color 0.3s;
      
      border-bottom: 1px solid rgba(241, 201, 19, 0.647);
      &:last-child {
        border-bottom: none;
      }

      &:hover {
       color:#F0B90B;
      }
    }
  }

  .overlay {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
}

.auth-container {
  margin-top: 90px;
  padding: 1rem;

  .auth-button {
    background-color: #F0B90B;
    color: black;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;

    &:hover {
      background-color: darken(#F0B90B, 10%);
    }

    &.logout {
      background-color: #ff4d4d;
      color: white;

      &:hover {
        background-color: darken(#ff4d4d, 10%);
      }
    }

    &.google {
      display: flex;
      align-items: center;
      background-color: white;
      color: black;
      border: 1px solid #ccc;

      img {
        height: 20px;
        margin-left: 0.5rem;
      }

      &:hover {
        background-color: darken(white, 10%);
      }
    }
  }

  .small-blue-text {
    color: #F0B90B;
    font-size: 0.9rem;
    margin-top: 0.5rem;
  }
}
