@import "./components/index.scss";
@import "./pages/index.scss";

.alert {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 500px;
    padding: 20px;
    background-color: #1f1f1f;
    color: #F0B90B;
    border: 1px solid #F0B90B;
    border-radius: 15px;
    text-align: center;
    z-index: 7000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    
    @media (max-width: 600px) {
      width: 95%;
      padding: 15px;
      font-size: 1.2rem;
    }
    
    @media (max-width: 400px) {
      width: 98%;
      padding: 10px;
      font-size: 1rem;
    }
  }
  