body {
  font-family: "Lato", sans-serif;
  background-color: #1D1D1F;
  color: #FFFFFF;
}

.homepage {
  display: flex;
  justify-content: center;
  padding: 20px;
  padding-top: 100px;
  background-color: #121212;

  .left-panel {
    max-width: 500px;
    width: 100%;
    background-color: #1f1f1f;
    padding: 40px 20px; // 40px de relleno en la parte superior e inferior, 20px en los lados
    border-radius: 12px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3), 0 0 15px rgba(240, 185, 11, 0.9);
    text-align: center;
    padding-bottom: 50px;
    margin-top: 50px;

  

    h1, h3 {
      margin: 10px 0;
      color: #F0B90B;
    }

    .logo {
      width: 250px;
      animation: logoAnimation 1s forwards;
      &.spinning {
        animation: spin 3s linear infinite;
      }
    }

    .logs {
      margin-top: 20px;
      font-size: 14px;
      color: #c9c9c9;
    }

    .input-box {
      width: 100%;
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 4px;
      border: 1px solid #333;
      background-color: #282828;
      color: #c9c9c9;
      font-size: 20px;
    }

    .input-label {
      display: block;
      margin-bottom: 5px;
      color: #F0B90B;
    }

    .component-button {
      display: block;
      width: 100%;
      padding: 10px;
      margin-top: 10px;
      border: none;
      border-radius: 4px;
      background-color: #F0B90B;
      color: #1f1f1f;
      cursor: pointer;

      &:disabled {
        background-color: #777;
        cursor: not-allowed;
      }
    }
  }

  @media (max-width: 600px) {
    padding: 10px;

    .left-panel {
      padding: 15px;

      h1 {
        font-size: 20px;
      }
    }
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes logoAnimation {
  0% {
    width: 0px;
    transform: rotate(0deg);
  }
  100% {
    width: 250px; // Corregido a 250px para coincidir con el tamaño original del logo
    transform: rotate(-360deg);
  }
}




.checkbox-container {
  display: flex;
  align-items: center; // Esto centra verticalmente el checkbox con respecto al texto.
  gap: 15px; // Aumentamos el espacio entre el checkbox y el texto.

  h3 {
    margin: 0;
  }

  input[type="checkbox"] {
    appearance: none;
    background-color: #282828;
    width: 25px; // Aumentamos el tamaño del checkbox
    height: 25px; // Aumentamos el tamaño del checkbox
    border: 2px solid #F0B90B;
    cursor: pointer;
    position: relative;

    &:checked::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 16px; // Aumentamos el tamaño del indicador cuando está marcado
      height: 16px; // Aumentamos el tamaño del indicador cuando está marcado
      background-color: #F0B90B;
      transform: translate(-50%, -50%);
      border-radius: 2px;
    }
  }
}



*,
*::before,
*::after {
    box-sizing: border-box;


  //ICONo telefono// modo responsive
}
.pc-button {
  position: fixed;
  top: 23%;
  right: 45px;
  transform: translateY(-50%);
  z-index: 9999;
  padding: 20px;
 
}

.pc-button a {
  display: block;
  text-align: center;
}

.pc-button img {
  width: 30px; // Tamaño del icono de celular
  height: 50px;
}
