.swap {
  width: 90%;
  max-width: 800px;
  background-color: #1f1f1f;
  padding: 4vw 2vw 8vw;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3), 0 0 15px rgba(240, 185, 11, 0.9);
  text-align: center;
  margin: 4vh auto;

  h2 {
    margin: 2vh 0;
    color: #f0b90b;
  }

  .toggle-button {
    margin: 2vh 0;
    padding: 1vw 2vw;
    border: none;
    border-radius: 4px;
    background-color: #f0b90b;
    color: #1f1f1f;
    cursor: pointer;

    &:hover {
      background-color: #d4a30a;
    }
  }

  .input-box {
    width: 100%;
    padding: 1vw;
    margin-bottom: 2vh;
    border-radius: 4px;
    border: 1px solid #333;
    background-color: #282828;
    color: #c9c9c9;
    font-size: 1vw;
  }

  .input-label {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1vh;
    color: #f0b90b;

    img {
      max-width: 60px;
      max-height: 60px;
      margin: 0 15px;
    }
  }

  .input {
    width: 100%;
    padding: 1vw;
    margin-bottom: 2vh;
    border-radius: 4px;
    border: 1px solid #333;
    background-color: #282828;
    color: #c9c9c9;
    font-size: 2vw;

    &::placeholder {
      color: #c9c9c9;
      font-size: 1.5vw;
      text-align: center;
    }
  }

  .component-button {
    display: block;
    width: 100%;
    padding: 1vw;
    margin-top: 2vh;
    border: none;
    border-radius: 4px;
    background-color: #f0b90b;
    color: #1f1f1f;
    cursor: pointer;

    &:disabled {
      background-color: #777;
      cursor: not-allowed;
    }
  }

  .component-button-verde,
  .component-button-rojo {
    display: block;
    width: 100%;
    padding: 1vw;
    margin-top: 2vh;
    border: none;
    border-radius: 4px;
    color: #1f1f1f;
    cursor: pointer;
    font-size: 2.5vw;

    &:hover {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.3), 0 0 15px rgba(240, 185, 11, 0.9);
    }
  }

  .component-button-verde {
    background-color: #3c9e28;
  }

  .component-button-rojo {
    background-color: #f94b17;
  }

  .allowance-info {
    color: #32cd32;
    margin-top: 10px;
  }

  .frozen-balances {
    margin-top: 10px;

    div {
      color: #32cd32;
      margin-top: 10px;
    }
  }

  .conversion-rate {
    margin-top: 10px;
    span {
      color: #f0b90b;
    }
  }

  .token-logo {
    margin: 0 10px;
  }

  .arrow-logo {
    margin: 0 10px;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    .modal-content {
      background-color: #2a2a2a;
      padding: 2vw;
      border-radius: 12px;
      text-align: center;
      box-shadow: 0 0 20px rgba(241, 239, 216, 0.5), 0 0 15px rgba(240, 185, 11, 0.7);
      animation: slide-down 0.3s ease-out;

      p {
        margin: 2vh 0;
        color: #f0b90b;
        font-size: 2.5vw;
      }

      .modal-buttons {
        display: flex;
        justify-content: space-around;
        margin-top: 5vh;

        .component-button {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 6vw 3vw 10vw;

    h2 {
      font-size: 5vw;
    }

    .logs {
      margin-top: 4vh;
      font-size: 2vw;
    }

    .input-box {
      padding: 2vw;
      font-size: 2vw;
    }

    .input-label {
      margin-bottom: 2vh;
      font-size: 3vw;

      img {
        max-width: 40px;
        max-height: 40px;
      }
    }

    .input {
      padding: 2vw;
      font-size: 4vw;

      &::placeholder {
        font-size: 3vw;
      }
    }

    .component-button,
    .component-button-verde,
    .component-button-rojo {
      padding: 2vw;
      font-size: 4vw;
    }

    .modal-content {
      padding: 4vw;

      p {
        font-size: 5vw;
      }

      .modal-buttons {
        margin-top: 4vh;
      }
    }
  }

  @media (max-width: 480px) {
    padding: 8vw 4vw 12vw;

    h2 {
      font-size: 7vw;
    }

    .logs {
      margin-top: 4vh;
      font-size: 3vw;
    }

    .input-box {
      padding: 3vw;
      font-size: 3vw;
    }

    .input-label {
      margin-bottom: 3vh;
      font-size: 4vw;

      img {
        max-width: 30px;
        max-height: 30px;
      }
    }

    .input {
      padding: 3vw;
      font-size: 5vw;

      &::placeholder {
        font-size: 4vw;
      }
    }

    .component-button,
    .component-button-verde,
    .component-button-rojo {
      padding: 3vw;
      font-size: 5vw;
    }

    .modal-content {
      padding: 5vw;

      p {
        font-size: 6vw;
      }

      .modal-buttons {
        margin-top: 6vh;
      }
    }
  }
}
