/* proyecto-hormiga.scss */
.proyecto-hormiga {
    margin-top: 100px !important; /* Espacio para evitar que el navbar tape el contenido */
    padding: 20px 30px; /* Agregado para dar un espacio interno y que el contenido no esté justo al borde */
    background-color: #1f1f1f;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3), 0 0 15px rgba(240, 185, 11, 0.9);
    margin: 20px auto; /* Modificado para que no tenga tanto margen en la parte superior */
    max-width: 800px;

    @media (max-width: 768px) {
        padding: 15px 20px;
    }

    @media (max-width: 480px) {
        padding: 10px 15px;
        margin-top: 80px !important;
    }
}

.proyecto-hormiga h1 {
    text-align: center;
    color: gold;
    margin-bottom: 30px; /* Aumentado para dar más espacio después del título */
    font-size: 34px; /* Aumentado para darle más presencia al título */
    font-weight: bold; /* Para que el título tenga más fuerza visualmente */

    @media (max-width: 480px) {
        font-size: 28px;
        margin-bottom: 20px;
    }
}

.proyecto-hormiga ol {
    line-height: 1.6;
    padding-left: 20px; /* Agregado para darle un espacio y que los números no estén justo al borde */

    @media (max-width: 480px) {
        padding-left: 15px;
    }
}

.proyecto-hormiga ol > li {
    margin-bottom: 15px; /* Aumentado para que cada elemento de la lista tenga más espacio entre ellos */

    @media (max-width: 480px) {
        margin-bottom: 10px;
    }
}

.proyecto-hormiga ul {
    margin-top: 10px; /* Agregado para dar espacio antes de cada lista no ordenada */
    margin-bottom: 20px; /* Espacio después de cada lista no ordenada */
}

.proyecto-hormiga ul > li {
    margin-bottom: 10px; /* Espacio entre cada ítem de la lista no ordenada */
}

.imagen-container {
    max-width: 100%; // Limita el ancho máximo del contenedor
    overflow: hidden; // Oculta cualquier desbordamiento de la imagen
    margin-bottom: 20px; // Añade un espacio entre las imágenes
    display: flex; // Utiliza flexbox para centrar la imagen
    justify-content: center; // Centra horizontalmente la imagen
}

.imagen {
    max-width: 100%; // Limita el ancho máximo de la imagen
    height: auto; // Mantiene la relación de aspecto original
}

// Estilos para el contenedor del video de YouTube
.youtube-video {
    max-width: 100%; // Limita el ancho máximo del contenedor
    overflow: hidden; // Oculta cualquier desbordamiento del video
    margin-bottom: 20px; // Agrega un espacio entre el video y otros elementos
    display: flex; // Utiliza flexbox para centrar el video
    justify-content: center; // Centra horizontalmente el video

    iframe {
        max-width: 100%; // Limita el ancho máximo del video
        height: auto; // Mantiene la relación de aspecto original
        aspect-ratio: 16 / 9; // Mantiene la relación de aspecto 16:9
    }
}
