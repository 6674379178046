/* Contenedor de la calificación con estrellas */
.star-rating {
    display: flex; /* Usa flexbox para alinear los elementos hijos en una fila */
    align-items: center; /* Alinea verticalmente los elementos hijos en el centro */
}

/* Estilo base para cada estrella */
.star {
    font-size: 29px; /* Tamaño de la fuente para las estrellas */
    color: #ccc; /* Color de las estrellas cuando no están llenas (gris claro) */
}

/* Estilo para las estrellas llenas */
.star.filled {
    color: #F0B90B; /* Color de las estrellas llenas (doradas) */
}

/* Estilo para las estrellas a medio llenar */
.star.half {
    position: relative; /* Posiciona el elemento relativo a su contenedor para permitir el uso de ::after */
    color: #F0B90B; /* Color de la mitad llena de la estrella (dorada) */
}

/* Estilo para el pseudo-elemento que crea el efecto de media estrella */
.star.half::after {
    content: '☆'; /* Contenido del pseudo-elemento: una estrella vacía */
    color: #ccc; /* Color de la mitad vacía de la estrella (gris claro) */
    position: absolute; /* Posiciona el pseudo-elemento absolutamente dentro de su contenedor relativo */
    left: 0; /* Posiciona el pseudo-elemento en el borde izquierdo del contenedor */
    width: 50%; /* Ancho del pseudo-elemento, representando la mitad de la estrella */
    overflow: hidden; /* Oculta la parte del pseudo-elemento que sobrepasa su contenedor */
}
