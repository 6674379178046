.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    background-color: #121314;
    padding: 0.32rem 2rem;
    margin-top: 2rem;
    font-size: 1em;
    color: #F0B90B;
    border-top: 1px solid #333;

    .footer-desc {
        cursor: pointer;
        text-decoration: none;
        color: #F0B90B;

        &:hover {
            color: #fff;
        }
    }

    .terms-text {
        color: white;
        max-width: 100%; // Make sure the text doesn't overflow
        margin: 1rem 0;
        p, ul, li {
            margin-bottom: 1rem;
        }
        ul {
            padding-left: 1.5rem; // Indent the list for better readability
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        text-align: left;
        padding: 1rem;
    }

    @media (max-width: 480px) {
        font-size: 0.9em; // Adjust font size for smaller screens
        .terms-text {
            font-size: 0.9em;
        }
    }
}
