.loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .loader-image {
        display: block;
        margin: auto;
        max-width: 150px;
        filter: opacity(0.5);
        @keyframes imageRotate1 {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(720deg);
            }
        }

        animation-name: imageRotate1;        
        animation-duration: 3s;
        animation-iteration-count: infinite;    
        animation-timing-function: ease-in-out;
    }
}