.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #121212;
  color: #ffffff;
  min-height: 100vh;
  width: 100%;
  padding: 20px;

  h2 {
    text-align: center;
    margin: 20px 0;
    font-size: 1.5em;
  }

  .button-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 10px 0;

    .btn-box {
      margin: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
      border-radius: 12px;
      padding: 10px;
      transition: transform 0.3s;

      &:hover {
        transform: scale(1.05);
      }

      .btn-image {
        width: 120px;
        height: 120px;
        margin-bottom: 10px;
      }

      h4 {
        color: #ffffff;
        font-size: 1em;
      }
    }
  }
}

.ant-hormig {
  margin: 20px 0;
  max-width: 100px;
  animation: grow 7s forwards;

  @keyframes grow {
    0% {
      width: 1px;
    }
    100% {
      width: 140px;
    }
  }
}

.ant-image {
  margin: 20px 0;
  width: 0px;
  animation: rotateAndShrink 7s forwards;

  @keyframes rotateAndShrink {
    0% {
      width: 0px;
      transform: rotate(0deg);
    }
    100% {
      width: 170px;
      transform: rotate(360deg);
    }
  }
}

.socialIcon, .socialIcon2, .socialIcon3 {
  position: fixed;
  right: 15px;
  z-index: 1000;
  width: 40px;
  height: auto;

  img {
    width: 100%;
    height: auto;
    transition: filter 0.3s;

    &:hover {
      filter: drop-shadow(0px 0px 7px rgba(255, 255, 255, 0.8));
    }
  }
}

.socialIcon {
  top: 100px;
}

.socialIcon2 {
  top: 180px;
}

.socialIcon3 {
  top: 260px;
}

.text-content {
  width: 100%;
  max-width: 600px;
  text-align: center;

  h2, a {
    margin: 0;
    padding: 10px 0;
    color: rgb(243, 182, 28);
    cursor: pointer;
    text-decoration: none;

    &:hover {
      color: #fff;
    }
  }

  iframe {
    width: 100%;
    max-width: 532px;
    height: auto;
  }
}

/* Ajustes específicos para pantallas pequeñas */
@media (max-width: 600px) {
  .ant-hormig {
    max-width: 80px;

    @keyframes grow {
      100% {
        width: 100px;
      }
    }
  }

  .ant-image {
    @keyframes rotateAndShrink {
      100% {
        width: 120px;
      }
    }
  }

  .socialIcon, .socialIcon2, .socialIcon3 {
    width: 30px;

    img {
      width: 30px;
    }
  }

  .text-content {
    padding: 10px;

    h2, a {
      font-size: 1.2em;
    }
  }
}
