.mypage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #121212;
  width: 100%;
  margin-top: 10px;
}

.mypage-header .mypage-connect-btn,
.mypage-header .component-button {
  background-color: #f0b90b;
  color: #000;
  padding: 8px 15px;
  border: none;
  border-radius: 1px;
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  &:hover {
    background-color: #f0b90b;
  }
}

.nft-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.nft-list .nft-card {
  width: 100%;
  max-width: 600px;
  padding: 15px;
  border-radius: 7px;
  background-color: #282828;
  box-shadow: 0 4px 6px rgb(0, 0, 0);
  margin-bottom: 15px;

  &:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3), 0 0 15px rgba(240, 185, 11, 0.9);
  }

  h1 {
    font-size: 24px;
    margin-bottom: 15px;
    color: #d8a007;
    text-align: center;
  }
}

.nft-list .nft-card .body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nft-list .nft-card .body img {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 5px;
  margin-bottom: 15px;
}

.nft-list .nft-card .body .text-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #c9c9c9;

  p {
    font-size: 16px;
    margin-bottom: 10px;
    text-align: justify;
  }

  .address-input {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #f0b90b;
    margin-top: 8px;
    width: 100%;
  }

  button {
    margin-top: 12px;
    width: 100%;
  }
}

.filter-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 0;
  background-color: #3333337a;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 10;

  label {
    color: #e0e0e0;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 18px;
  }

  select {
    padding: 10px 15px;
    background-color: #f0b90b;
    border-radius: 4px;
    color: #000;
    cursor: pointer;
    font-size: 16px;

    &:hover {
      background-color: #d8a007;
    }
  }

  button {
    background-color: #f0b90b;
    color: #000;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    font-weight: 600;
    font-size: 16px;
    margin-top: 10px;

    &:hover {
      background-color: #d8a007;
    }
  }
}

.no-nfts-message {
  color: #e0e0e0;
  background-color: #2a2a2c;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 16px;
}

.nft-list .nft-card .body .text-content .liberar-button {
  background-color: #3c9e28;
  color: white;
}

.nft-list .nft-card .body .text-content .reembolsar-button {
  background-color: #f94b17;
  color: white;
}

.nft-list .nft-card .body .text-content .transfer-button {
  background-color: #007bff;
  color: white;
}

.component-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid goldenrod;
  border-radius: 5px;
  font-size: 16px;
  color: white;
  cursor: pointer;
  text-align: center;
  filter: drop-shadow(0 0 3px #f0b90b);
}

.component-button:hover {
  background: linear-gradient(252.53deg, #271f38 -21.28%, rgba(127, 67, 255, 0) 61.97%);
  border: 1px solid #f0b90b;
  filter: drop-shadow(0 0 3px #f0b90b);
}

@media (max-width: 768px) {
  .mypage-container {
    padding: 10px;
  }

  .nft-list .nft-card {
    padding: 10px;
  }

  .nft-list .nft-card .body .text-content p {
    font-size: 14px;
  }

  .filter-bar label,
  .filter-bar select,
  .filter-bar button {
    font-size: 14px;
  }

  .no-nfts-message {
    font-size: 14px;
  }

  .component-button {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .mypage-container {
    padding: 5px;
  }

  .nft-list .nft-card {
    padding: 5px;
  }

  .nft-list .nft-card .body .text-content p {
    font-size: 12px;
  }

  .filter-bar label,
  .filter-bar select,
  .filter-bar button {
    font-size: 12px;
  }

  .no-nfts-message {
    font-size: 12px;
  }

  .component-button {
    font-size: 12px;
  }
}
